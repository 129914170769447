
.report-table-footer {
  padding: 0px;
  margin: 0px;
}

.additional-loading-indicator {
  height: 44px;
  width: 180px;
  display: block;
  margin: 20px auto 20px auto;
}
.additional-loading-btn {
  height: 44px;
  width: 180px;
  display: block;
  margin: 20px auto 20px auto;

  background: #ceebe9;
  cursor: pointer;

  min-width: 200px;
  padding: 0px 15px;
  box-sizing: border-box;
  border-radius: 30px;
}
.clear-selected-date-button {
}
