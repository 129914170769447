
//フォントサイズ指定はrem指定にする
//10pxを1remとする
//フォントウェイトの指定は数値で行う
//Light:300、Regular:400、Medium:500、Bold:700

html {
font-size: 62.5%;
}

body{
font-family: 'Noto Sans JP', sans-serif;
letter-spacing: 0.1em;
font-feature-settings: "palt";
}


.br-sp {
    display: none;
}

.itemLeft{
    text-align: left!important;
}
.itemCenter{
text-align: center;
}
.itemRight{
    float: right;
}

.mt{
    &-20{
        margin-top: 20px!important;
    }
    &-40{
        margin-top: 40px!important;
    }
    &-60{
        margin-top: 60px!important;
    }
    &--20{
        margin-top: -20px!important;
    }
    &--30{
        margin-top: -30px!important;
    }
    &--40{
        margin-top: -40px!important;
    }
}
.mb{
    &-0{
        margin-bottom: 0!important;
    }
    &-20{
        margin-bottom: 20px;
    }
    &-40{
        margin-bottom: 40px!important;
    }
    &-100{
        margin-bottom: 100px!important;
    }
}
.ml{
    &-0{
        margin-left: 0!important;
    }
    &-10{
        margin-left: 10px;
    }
    &-20{
        margin-left: 20px;
    }
    &-40{
        margin-left: 40px!important;
    }

}
.mr{
    &-0{
        margin-right: 0!important;
    }
    &-10{
        margin-right: 10px;
    }
    &-20{
        margin-right: 20px;
    }
    &-40{
        margin-right: 40px!important;
    }

}
.pl{
    &-0{
        padding-left: 0!important;
    }
    &-10{
        padding-left: 10px!important;
    }
    &-20{
        padding-left: 20px!important;
    }
    &-40{
        padding-left: 40px!important;
    }

}
.pr{
    &-0{
        padding-right: 0!important;
    }
    &-10{
        padding-right: 10px!important;
    }
    &-20{
        padding-right: 20px!important;
    }
    &-40{
        padding-right: 40px!important;
    }

}
.as{
    &-fs{
        align-self: flex-start;
    }
}
.grow{
    &-0{
        flex-grow: 0;
    }
    &-1{
        flex-grow: 1;
    }
}
//pcのみ改行させるときは<br class="br-pc">
//spのみ改行させるときは<br class="br-sp">
@media screen and (max-width: 34em) {
    .br-pc {
        display: none;
    }

    .br-sp {
        display: inline-block;
    }
}
