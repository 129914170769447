//変数一覧
$screen_width: 62em; //tab切り替えサイズ
$sp_width: 34em; //スマホ切り替えサイズ

$color_main: #05A59C;
$color_heavy: #1E7570;
$color_bg:#F0F9F8;
$color_background_gray: #FBFBFD;
$color_border: #DADADA;
$color_yellow: #FFF617;

$font_en:Noto Sans;
$font_jp:Noto Sans JP;

//  Active State
$vs-state-active-bg: #F0F9F8 !default;
$vs-state-active-color: #DADADA !default;

//  Selected
$vs-border-style: solid !default;
$vs-border-width: 1px !default;

$vs-selected-bg: #f0f0f0 !default;
$vs-selected-border-color: $color_border !default;
$vs-selected-border-style: $vs-border-style !default;
$vs-selected-border-width: $vs-border-width !default;