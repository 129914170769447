@import "settings/init";
@import "settings/variables";
@import "settings/normalize";

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Noto Sans JP";
  background-color: #fff;

  display: flex;
  flex-flow: column;
  min-height: 100vh;
  height: 100vh;
}
#app{
}
.page-content{
}
.container{
  height: calc(93vh);

}
input {
  font-size: 1.5rem;
  border-radius: 6px;
  border: 1.8px solid $color_border;
  box-sizing: border-box;
  &:focus {
    border: 1.8px solid $color_main;
    z-index: 10;
    outline: 0;
  }
}

a {
  font-family: Noto Sans JP;
  font-style: normal;
  font-size: 1.5rem;
  line-height: 2;
  color: #0084cf;
}
.light-text {
  font-family: Noto Sans JP;
  font-style: normal;
  font-size: 1.5rem;
  line-height: 2;
  margin: 10px 10px;
  color: #B9B9B9;
}
.light-text-body {
  font-family: Noto Sans JP;
  font-style: normal;
  font-size: 1rem;
  line-height: 1.4;
  margin: 10px 10px;
  color: #B9B9B9;
}
.body1 {
  font-family: Noto Sans JP;
  font-style: normal;
  font-size: 1.6rem;
  line-height: 2;
  margin: 16px 10px;
  text-align: center;
}
.body-inline {
  display: inline;
  font-family: Noto Sans JP;
  font-style: normal;
  font-size: 1.5rem;
  text-align: left;
}
a:hover {
  opacity: 0.6;
  transition: 0.2s ease-in-out;
}
.container#dashbord {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 450px 1fr;

  header {
    grid-row: 1 / 2;
    grid-column: 1 / 3;
  }
  aside {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
  }
  main {
    grid-row: 2 / 3;
    grid-column: 2 / 3;
  }
}
.container#rightside {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr 320px;

  header {
    grid-row: 1 / 2;
    grid-column: 1 / 3;
  }
  main {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
  }
  aside {
    grid-row: 2 / 3;
    grid-column: 2 / 3;
  }
}

// header
nav {
  display: flex;
  border-bottom: 1px solid $color_border;
  height: 80px;

  align-items: center;
  justify-content: space-between;
}
.nav-left {
  display: flex;
  padding-left: 20px;
}
.nav-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 30px;
  &-properties {
    margin-left: 10px;
    text-align: right;
    font-family: Noto Sans JP;
    font-style: normal;
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 1.4;
    color: $color_heavy;
    white-space: nowrap;
  }

  &-account {
    a {
    display: block;
    height: 80px;
    width: 186px;
    padding-top: 27px;
    padding-left: 60px;

    color: $color_heavy;
    text-decoration: none;
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: 25px 50%;
    font-size: 1.3rem;
    }
  }
}
.icon-account {
  font-family: Noto Sans JP;
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 2;
  padding-top: 25px;
  padding-left: 70px;
  display: block;
  width: 186px;
  height: 80px;
  color: $color_heavy;
  text-decoration: none !important;
  background-repeat: no-repeat;
  background-size: 28px;
  background-position: 35px 50%;
  background-image: url(~@/assets/img/icon-account.svg);
}
.company-name {
  display: inline-flex;
  align-self: center;

  a {
    height: 80px;
    width: 260px;
    padding-top: 20px;
    padding-left: 80px;
    color: #1E7570;
    text-decoration: none;
    background-image: url(/img/wooday-logo.d3d01bf4.svg);
    background-repeat: no-repeat;
    background-size: 50px;
    background-position: 20px 40%;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    line-height: 2;
    font-size: 2.3rem;
    letter-spacing: 0.2em;
  }
}

.menu-item {
  display: flex;
  align-items: center;

  justify-content: center;

  list-style: none;
  margin: 0;
  padding: 0;
  border-right: 1px solid $color_border;

  li {

    a {
      height: 80px;
      display: inline-flex;
      justify-content: center;
      align-self: center;
      line-height: 80px;
    
      width: 186px;
      
      border-left: 1px solid $color_border;

      
      font-family: Noto Sans JP;
      font-style: normal;
      font-weight: bold;
      font-size: 1.4rem;

      color: $color_heavy;
      text-decoration: none;

      background-repeat: no-repeat;
      background-size: 24px;
      background-position-y: 50%;

      &.icon {
        &-dashboard {
          background-image: url(~@/assets/img/icon-dashboard.svg);
          background-position-x: 20%;
          padding-left: 37px;
        }
        &-diary {
          background-image: url(~@/assets/img/icon-diary.svg);
          background-position-x: 30%;
          padding-left: 32px;
        }
        &-manage {
          background-image: url(~@/assets/img/icon-manage.svg);
          background-position-x: 30%;
          padding-left: 32px;
        }
        &-member {
          background-image: url(~@/assets/img/icon-member.svg);
          background-position-x: 20%;
          padding-left: 35px;
        }
      }
    }
  }
}
// header
// dropdown

.dropdown {
  margin: 0 0 0 auto;
  position: relative; /* 絶対配置の基準を設定 */
  &-body {
    width: 230px;
    background: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    border: 2px solid #c4c4c4;
    overflow: hidden;
    position: absolute;
    z-index: 100;
    top: 70px;
    right: 0;
  }

  &-list {
    margin: 0;
    padding: 10px 20px;
    list-style: none;
    li {
      margin: 10px 0;
      padding: 0;
      a {
        display: inline;
        font-family: Noto Sans JP;
        font-style: normal;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 2.5;
        color: #000;
        text-decoration: none;
        padding: 0;
      }
    }
  }

  .dropdown-list li:last-of-type {
    border-top: 1px solid #dbdbdb;
    padding-top: 10px;
    margin-top: 20px;
  }

  /*
  &-body {
    display: none;
  }
  */

  &-btn.is-open + &-body {
    display: block;
    z-index: 4;
  }
}

#rightside .dropdown-body {
  display: none;
}

.outlink::after {
  display: inline-flex;
  align-items: center;
  position: relative;
  top: -4px;
  content: "";
  height: 14px;
  width: 14px;
  //width: 100%;
  margin-left: 10px;
  background-image: url(~@/assets/img/icon-outlink.svg);
  background-repeat: no-repeat;
  background-position: 0;
  background-size: 100%;
}

//dropdown--!

// title

.content-title {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $color_border;

  height: 86px;

  padding-left: 40px;

  background-image: url(~@/assets/img/title-back.jpeg);
  background-position: 0% center;
  background-repeat: no-repeat;
  background-size: 120%;

  h1 {
    font-family: Noto Sans JP;
    font-style: normal;
    font-weight: bold;
    font-size: 2rem;
    color: $color_heavy;
  }
}

// title--!

//aside dashbord

#dashbord aside {
  background: #f9f9f9;
  border-right: 1px solid #e5e5e5;
}
.aside-head {
  .aside-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 10px 20px 10px 40px;
  }
  h2 {
    font-family: Noto Sans JP;
    font-style: normal;
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 2;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    color: #262626;
  }
  p {
    font-family: Noto Sans JP;
    font-style: normal;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 2;
    text-align: right;
    margin: 0;
    padding: 0;
  }
}
.aside-summary {
  margin: 0;
  padding: 0;
  list-style-type: none;
  background-color: #fff;
  border-bottom: 1px solid #e5e5e5;

  li {
    margin: 0;
    padding: 5px 20px 5px 40px;
    border-top: 1px solid #e5e5e5;

    h3 {
      margin: 0;
      font-family: Noto Sans JP;
      font-style: normal;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 2;
    }

    p {
      margin: 0;
      font-family: Noto Sans JP;
      font-style: normal;
      font-size: 1.4rem;
      line-height: 2;
      text-align: right;
      margin: 0;
      padding: 0;
    }
    span {
      font-weight: bold;
      font-size: 2rem;
      line-height: 2;
      margin-right: 10px;
      margin-left: 10px;
    }
  }
}

//aside dashbord --!

//aside rightside

#rightside aside {
  background: #f9f9f9;
  border-left: 1px solid #e5e5e5;
  padding: 20px;

  h2 {
    font-family: Noto Sans JP;
    font-style: normal;
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 2;
  }
  h3 {
    font-family: Noto Sans JP;
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 2;
    margin: 0;
  }
}
.checkbox-list {
  margin: 0px 0px 20px 0px;
  padding: 0px;
  list-style-type: none;

  display: flex;
  flex-wrap: wrap;

  li {
    min-width: 49%;
    font-family: Noto Sans JP;
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 2;
  }
  li:first-of-type {
    width: 100%;
  }
  input[type="checkbox"] {
    margin-right: 4px;
    display: none;
  }
  .checkbox {
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    padding: 5px 30px;
    position: relative;
    width: auto;
  }
  .checkbox:disabled {
    border: 2px solid $color_main;
    content: "";
    display: block;
    height: 16px;
    left: 5px;
    margin-top: -8px;
    position: absolute;
    top: 50%;
    width: 16px;
    opacity: 0.5;
  }
  .checkbox::before {
    background: #fff;
    border: 2px solid $color_main;
    content: "";
    display: block;
    height: 16px;
    left: 5px;
    margin-top: -8px;
    position: absolute;
    top: 50%;
    width: 16px;
  }
  .checkbox::after {
    border-right: 3px solid $color_heavy;
    border-bottom: 3px solid $color_heavy;
    content: "";
    display: block;
    height: 9px;
    left: 10px;
    margin-top: -7px;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: rotate(45deg);
    width: 5px;
  }
  input[type="checkbox"]:checked + .checkbox::after {
    opacity: 1;
  }
  input[type="radio"] {
    margin-right: 4px;
    display: none;
  }
}
.radio-list {
  @extend .checkbox-list;
  flex-direction: column;
  input[type="text"] {
    font-size: 1.6rem;
    width: 250px;
    padding: 10px;
  }
  .radio {
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    padding: 5px 30px;
    position: relative;
    width: auto;
  }
  .radio::before {
    background: #fff;
    border: 2px solid $color_main;
    border-radius: 50%;
    content: "";
    display: block;
    height: 16px;
    left: 5px;
    margin-top: -8px;
    position: absolute;
    top: 50%;
    width: 16px;
  }
  .radio::after {
    background: $color_heavy;
    border-radius: 50%;
    content: "";
    display: block;
    height: 10px;
    left: 10px;
    margin-top: -4px;
    opacity: 0;
    position: absolute;
    top: 52%;
    width: 10px;
  }
  input[type="radio"]:checked + .radio::after {
    opacity: 1;
  }
}
//aside rightside --!

//main
#dashbord main,
#rightside main {
  background-image: url(~@/assets/img/main-back.jpeg);
  background-position: 105% 105%;
  background-repeat: no-repeat;
  background-size: 250px;
  overflow: scroll;
}
.data-header {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-family: Noto Sans JP;
    font-style: normal;
    font-weight: bold;
    font-size: 2rem;
    line-height: 1;
  }
}
.main-inner {
  padding: 10px 60px 100px;
}
.sub-title {
  font-family: Noto Sans JP;
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 2;
  color: #262626;
}

.data-table {
  border-collapse: separate;
  border-spacing: 0;
  overflow: hidden;
  /*
  max-width: 1048px;*/
  min-width: 680px;
  width: 100%;
  border: 3px solid $color_main;
  border-radius: 10px;
  background-color: #fff;
  & tr:nth-child(even) td {
    background: #f8f8f8;
  }
  & th {
    color: $color_heavy;
    background-color: $color_bg;
    padding: 8px 20px;
    font-family: Noto Sans JP;
    font-style: normal;
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 2;
    text-align: start;
  }
  & td {
    font-family: Noto Sans JP;
    //text-align: center;
    font-style: normal;
    font-size: 1.5rem;
    line-height: 2;
    padding: 8px 20px;
    color: #000000;
  }
  .cell-nw{
    white-space: nowrap;
  }
}
.date-selector {
  display: flex;
  font-family: Noto Sans JP;
  font-style: bold;
  font-size: 1.4rem;
  input {
    width: 140px;
    margin: 0 10px;
    padding: 10px;
    font-size: 1.4rem;
    letter-spacing: 0.1rem;
  }
}
.date-picker {
  display: flex;
}
.data-pager {
  display: flex;
  justify-content: center;
  list-style-type: none;
  margin: 40px 0;
  li {
    margin: 0 10px;
  }
  a {
    background-color: $color_main;
    width: 30px;
    height: 30px;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    text-align: center;
    color: #fff;
    font-family: Noto Sans JP;
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    text-decoration: none;
    line-height: 1.4;
    &.current {
      background-color: $color_heavy;
    }
  }
}
//main --!

//section-tab
.section-tab {
  margin: 40px 0px 0px 0px;
  padding: 0 0 0 40px;
  display: flex;
  justify-content: flex-start;
  border-bottom: 3px solid $color_heavy;
  list-style-type: none;
  li {
    position: relative;
    top: 3px;
    border: 3px solid $color_main;
    border-bottom: none;
    border-radius: 10px 10px 0px 0px;
    margin-right: 20px;
    height: 77px;
    background: #f0f9f8;
  }
  a {
    padding: 18px 14px;
    display: inline-block;
    width: 200px;
    cursor: pointer;

    font-family: Noto Sans JP;
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: inherit;
    text-decoration: none;
    color: #000000;
    & span {
      float: right;
      font-family: Noto Sans JP;
      margin-left: 5px;
      font-style: normal;
      font-weight: 500;
      font-size: 1.4rem;
      b {
        font-style: normal;
        font-weight: bold;
        font-size: 4rem;
        margin-right: 5px;

        color: $color_heavy;
      }
    }
  }
  .current {
    height: 80px;
    border: 3px solid $color_heavy;
    border-bottom: 3px solid #fff;
    position: relative;
    top: 3px;
    background-color: #fff;
  }
}

//section-tab--!
//modal
.modalArea {
  // display: none;
  // position: fixed;
  // // z-index: 100; /*サイトによってここの数値は調整 */
  // top: 0;
  // left: 0;
  // width: 100%;
  // height: 100%;
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}

.modalBg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalWrapper {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  max-width: 700px;
  padding: 0px 0px 60px 0px;
  background-color: #fff;
  text-align: center;
  border-radius: 10px;
}
.modalContents {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  h3 {
    width: 100%;
    font-family: Noto Sans JP;
    font-style: normal;
    font-weight: bold;
    font-size: 2rem;
    line-height: 1;
    border-bottom: 2px solid $color_border;
    padding: 0 0 14px 0;
  }
  p {
    font-family: Noto Sans JP;
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 2;
    text-align: left;
  }
  input[type="text"],
  input[type="password"] {
    width: 200px;
    padding: 10px;
  }
}

.closeIconModal {
  position: absolute;
  top: 2rem;
  right: 2rem;
  font-size: 1.6rem;
  cursor: pointer;
}

.diary-content {
  display: flex;
  align-items: flex-start;

  padding: 40px;
}
.photo-area {
  list-style: none;
  margin: 0px;
  padding: 0px;
  min-width: 240px;
  li {
    margin-bottom: 20px;
    font-family: Noto Sans JP;
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 2;
  }
  img {
    display: block;
  }
}
.diary-txt {
  list-style: none;
  margin: 0px;
  margin-left: 40px;
  padding: 0px;
  min-width: 240px;
  li {
    text-align: left;
    font-family: Noto Sans JP;
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 2;
    display: flex;
    margin-bottom: 14px;
    span {
      width: 80px;
      min-width: 80px;
    }
  }
}
.form-area {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  width: 460px;
  text-align: left;

  li {
    font-family: Noto Sans JP;
    font-style: normal;
    font-weight: 500;
    font-size: 2.2rem;
    line-height: 2;

    span {
      display: inline-block;
      font-size: 1.4rem;
      line-height: 2;
      width: 140px;
      min-width: 140px;
    }
  }
}
.form-error {
  color: indianred;
  font-family: Noto Sans JP;
  font-style: normal;
  font-size: 1rem;
  text-align: end;
  margin: 0;
  padding: 0;
}

.form-error-left {
  color: indianred;
  font-family: Noto Sans JP;
  font-style: normal;
  font-size: 1rem;
  text-align: start;
  margin: auto;
  padding: 0;
}

//modal--!
.radio-list-modal{
  @extend .radio-list;
  text-align: left;
  .radio{
    padding-right: 10px;}
  select{
    padding: 10px;
  }
}

.target-select-container {
  flex: auto;
  padding: 10px;
  .target-select {
    display: inline;
    margin: 10px;
  }
}

.target-select {
  padding: 10px;
  font-size: 1.6rem;
  border-radius: 6px;
  border: 1.8px $color_border solid;
  &:focus {
    border: 1.8px $color_border solid;
    z-index: 10;
    outline: 0;
  }
}

.alert-link {
  color: #ff0000;
}

.form-btn {
  background: #ceebe9;
  cursor: pointer;
  min-width: 120px;
  padding: 0px 15px;
  border: 2px solid $color_main;
  box-sizing: border-box;
  border-radius: 30px;

  font-family: Noto Sans JP;
  font-style: normal;
  font-size: 1.3rem;
  line-height: 2;
}

.submit-button {
  display: inline;
  width: 200px;
  height: 40px;
  background-color: $color_main;

  font-family: Noto Sans JP;
  font-style: normal;
  font-size: 1.4rem;
  line-height: 2;
  color: #fff;
  border: none;
  border-radius: 6px;

  cursor: pointer;
}

.clear-button {
  width: 20px;
  height: 20px;
  text-align: center;
  cursor: pointer;
  padding: 0px;
  margin: auto 16px;
  background-color: transparent;
  border: none;
  background-image: url(~@/assets/img/icon-close-button-green.svg);
  background-repeat: no-repeat;
  background-size: 20px;
}

//login
.login-company-name {
  margin-bottom: 40px;
  a{
  height: 80px;
  width: 280px;
  padding-top: 30px;
  padding-left: 130px;
  margin-bottom: 40px;

  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;

  font-size: 3rem;
  letter-spacing: 0.2em;

  color: $color_heavy;
  text-decoration: none;
  background-image: url(~@/assets/img/wooday-logo.svg);
  background-repeat: no-repeat;
  background-size: 70px;
  background-position: 40px 40%;
}
}

#login main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-size: 3400px;
  height: 100vh;
  background-image: url(~@/assets/img/login-back.jpeg);
  background-repeat: no-repeat;
  background-position: center 140%;
}
.form-box {
  width: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: #ffffff;

  border: 1px solid $color_main;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  padding-bottom: 40px;
  margin-bottom: 100px;
}
.form-title {
  border-bottom: 1px solid $color_border;
  display: block;
  width: 100%;
  text-align: center;

  font-family: Noto Sans JP;
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 2;
  padding: 10px 0 10px 0;
  margin: 0;
}
.input-group {
  margin-top: 20px;
  margin-bottom: 20px;
}
.input-label {
  font-family: Noto Sans JP;
  font-style: normal;
  font-size: 1.4rem;
  line-height: 2;
}
.input-normal{
  background: #ffffff;
  border: 1px solid #dadada;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 1.8rem;
  width: 240px;
  padding: 10px;
  margin: 0 10px;
}
.input-long {
  background: #ffffff;
  border: 1px solid #dadada;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 1.8rem;
  width: 360px;
  padding: 10px;
}
label{
  cursor: pointer;
}
.text-error {
  font-family: Noto Sans JP;
  font-style: normal;
  font-size: 1.4rem;
  line-height: 2;
  color: #ff0000;
  margin-top: 20px;
}


.password-changer{
  padding-top: 30px;
  &-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 440px;
    margin-bottom: 20px;
    & input{
      width: 260px!important;
    }
    span{
      font-family: Noto Sans JP;
      font-style: normal;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 2;

      color: #000000;

    }
  }


}

.report-image-scroll-container {
  max-height: 400px;
  width: 240px;
}

.report-image {
  margin: 2px;
  border-radius: 2px;
  
}
.report-image-slider {
  .vueperslide__image {
    margin-bottom: 20px;
  }
}
.thumbnails {
  margin: auto;
  max-width: 300px;
}

.thumbnails .vueperslide {
  box-sizing: border-box;
  border: 1px solid #1E7570;
  transition: 0.3s ease-in-out;
  opacity: 0.7;
  cursor: pointer;
}

.thumbnails .vueperslide--active {
  box-shadow: 0 0 6px #1E7570;
  opacity: 1;
  border-color: #1E7570;
}

.vueperslide__image {
  background-size: contain!important;
  background-repeat: no-repeat!important;
}
.vueperslides__arrow {color:$color_main!important;}
.loader {
  color: #ceebe9;
  font-size: 9px;
  margin: 40px auto;
  width: 0.8em;
  height: 0.8em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader-small {
  color: #ceebe9;
  font-size: 8px;
  margin: 30px auto;
  width: 0.7em;
  height: 0.7em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
    0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
    0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
    0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
    0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
    0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
    0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em,
    0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
    0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
    0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
    0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
    0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
    0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
    0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
    0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em,
    0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
    0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}